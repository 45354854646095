.imageWrapper {
  display: inline-flex;
  position: relative;
  aspect-ratio: 9/16;
  width: 100%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.02),
    rgba(255, 255, 255, 0.012)
  );
  transition: all 0.3s ease-out;
  z-index: 1;
  border-radius: 32px;
  cursor: pointer;
  box-shadow: inset 2px 4px 16px hsla(0, 0%, 97%, 0.06);
}

.imageWrapper:has(.off)::before {
  opacity: 0;
}

.imageWrapper::after {
  content: "";
  border: 2px solid hsla(0, 0%, 100%, 0.05);
  -webkit-mask-image: linear-gradient(180deg, #000, transparent 75%);
  mask-image: linear-gradient(180deg, #000, transparent 75%);
  pointer-events: none;
  border-radius: inherit;
  position: absolute;
  inset: 0;
}

.imageWrapper::before {
  content: "";
  background-image: var(--image);
  background-size: cover;
  filter: blur(16px) saturate(1);
  transform: scale(0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: inherit;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.imageWrapper.loaded::before {
  opacity: 0.8;
}

.glowImage {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative;
  opacity: 1;
  transform: scale(1);
  filter: saturate(1);
  transition: transform 0.5s ease-out, filter 0.5s ease-out, opacity 0.5s linear;
}

img.off {
  opacity: 0;
  filter: saturate(0.2);
  transform: scale(1.05);
}

.imageWrapper:hover::before {
  transition: all 0.2s cubic-bezier(0.78, 0.265, 0, 1.65);
  filter: blur(32px) saturate(1.2);
}

.imageOverflowWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 32px;
  z-index: 1;
}

.App:not(.mobile):has(.imageWrapper:hover) .imageWrapper:not(:hover) {
  opacity: 0.5;
  filter: saturate(0.5);
}

@media (max-width: 600px) {
  .imageWrapper, .imageOverflowWrapper {
    border-radius: 16px;
  }
}