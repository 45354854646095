html {
  scrollbar-width: 16px;
  --text-color: hsla(0, 0%, 97%, 0.85);
  --gray: #202020;
  --brand: #98f43b;
  background: var(--gray);
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  font-family: "Inter", sans-serif;
  background: var(--gray);
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 874px;
  z-index: 3;
}

.containerGrid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
  width: 80%;
  max-width: 1200px;
}



.images {
  padding: 64px 0;
  padding-top: 16px;
  display: flex;
  align-items: start;
  justify-content: center;
  z-index: 2;
}

.zero {
  color: var(--text-color);
  font-size: 24px;
  opacity: 0.64;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 256px;
  padding-bottom: 64px;
}

.gloss::before {
  background: linear-gradient(to bottom right, #2f2f2f, #1F1F1F);
  box-shadow: inset 2px 4px 16px 0 hsla(0, 0%, 97%, 0.06),
    0 24px 24px -16px rgba(5, 5, 5, 0.09), 0 6px 13px 0 rgba(5, 5, 5, 0.1),
    0 6px 4px -4px rgba(5, 5, 5, 0.1), 0 5px 1.5px -4px rgba(5, 5, 5, 0.25);
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  z-index: -1;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
}

.gloss::after {
  border: 2px solid hsla(0, 0%, 100%, 0.05);
  -webkit-mask-image: linear-gradient(178deg, #000, transparent 75%);
  mask-image: linear-gradient(178deg, #000, transparent 75%);
  pointer-events: none;
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
}

.mobile .gloss::before {
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  background: linear-gradient(to bottom right,
      var(--gray),
      color-mix(in srgb, var(--gray), transparent 80%));
}

@keyframes headerSweep {
  from {
    background-position-x: 100%;
  }

  to {
    background-position-x: 0%;
  }
}

@media (max-width: 1400px) {
  .containerGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1000px) {
  .containerGrid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 600px) {
  .containerGrid {
    width: 90%;
    grid-gap: 16px;
  }
}