.dropDownWrapper {
    position: relative;
}

.dropDownWrapper.menuOpen{
    z-index: 2;
}

.menu {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: var(--gray);
    padding: 4px 0;
    border-radius: 8px;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.8);
    font-size: 13px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.64);
    transform: translateX(-50%);
}

.menu::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.08;
    z-index: 1;
}

.menuItem {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 0px 8px;
    cursor: pointer;
    height: 40px;
    align-items: center;
    z-index: 2;
}

.menuItem.active {
    background: rgba(0, 0, 0, 0.32);
}

.menuItem:hover {
    background: rgba(255, 255, 255, 0.1);
}

.menuItem .material-symbols-outlined {
    font-size: 22px;
}

.dropDownWrapper:has(.menu) .toggleButton .state {
    opacity: 0.04;
}