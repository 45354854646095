.hero {
    display: flex;
    position: relative;
    height: 600px;
    align-items: center;
    justify-content: center;
}

.heroImage {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 150%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: saturate(0);
    opacity: 0.2;
}

.heroImage::after {
    content: "";
    height: 300px;
    width: 100%;
    background: linear-gradient(to top, var(--gray), transparent);
    position: absolute;
    bottom: 0;
    left: 0;
}

.headerWrapper {
    opacity: 0;
}

.headerWrapper.visible {
    opacity: 1;
    transition: opacity 0.3s linear;
}

.headerWrapper h1 {
    font-size: 3em;
    margin: 0;
    text-align: center;
    background-image: linear-gradient(to right,
            var(--text-color) 33%,
            white,
            var(--text-color) 66%);
    color: transparent;
    background-clip: text;
    background-size: 600% 100%;
    background-position-x: 100%;
    animation: headerSweep 6s ease-in-out infinite;
    letter-spacing: 8px;
    overflow-wrap: anywhere;
    white-space: break-spaces;
    margin-right: -8px;
    transition: letter-spacing 1s cubic-bezier(0.05, 0.7, 0.1, 1.0);
}

h1.large {
    display: inline-block;
}

h1.small {
    display: none;
}

.headerWrapper.visible h1 {
    letter-spacing: 24px;
    margin-right: -24px;
}

.lottie {
    width: 400px;
    height: 400px;
    z-index: 2;
    position: absolute;
}

@media (max-width: 600px) {
    .hero {
        height: 400px;
    }

    .headerWrapper h1{
        font-size: 2.5rem;
        line-height: 64px;
    }

    .lottie {
        width: 300px;
        height: 300px;
    }

    h1.large {
        display: none;
    }

    h1.small {
        display: inline-block;
    }
}