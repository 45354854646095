.projectorScrim {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  pointer-events: all;
}

.projectorScrim .imageCard {
  padding: 32px;
  backdrop-filter: blur(12px);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectorScrim .imageCard::before {
  background: rgba(40, 40, 40, 0.8);
}

.projectorScrim .header {
  color: var(--text-color);
  height: 48px;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  width: 100%;
}

.projectorScrim .backButton {
  display: none;
  font-size: 20px;
  font-weight: 500;
  border-radius: 24px;
}

.projectorScrim .header h3 {
  flex: 1;
  font-size: 32px;
}

.projectorScrim .downloadButton {
  font-size: 20px;
  font-weight: 500;
  border-radius: 24px;
}

.projectorScrim .projectorImageWrapper {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}

.projectorScrim .projectorImageWrapper img {
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s linear;
  z-index: 2;
}

.projectorScrim .projectorImageWrapper img.loading {
  opacity: 0;
}

.projectorScrim .projectorImageWrapper img.loaded {
  animation: imageFadeIn 0.3s linear forwards;
}

@keyframes imageFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Mobile */
@media (max-width: 600px) {
  .projectorScrim .imageCard {
    background: var(--gray);
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    border-radius: 0px;
    padding: 16px;
  }

  .projectorScrim .backButton {
    display: flex;
  }

  .projectorScrim .header h3 {
    font-size: 24px;
  }
}
